export default class BotaParser {
    constructor() {
        this.cards = {
            "SA" : ['sa.png', 1, 1],
            "S2" : ['s2.png', 2, 2],
            "S3" : ['s3.png', 3, 3],
            "S4" : ['s4.png', 4, 4],
            "S5" : ['s5.png', 5, 5],
            "S6" : ['s6.png', 6, 6],
            "S7" : ['s7.png', 7, 7],
            "S8" : ['s8.png', 8, 8],
            "S9" : ['s9.png', 9, 9],
            "S10" :['st.png', 10, 10],
            "SJ" :['sj.png', 10, 11],
            "SQ" : ['sq.png', 10, 12],
            "SK" : ['sk.png', 10, 13],

            "DA" : ['da.png', 1, 1],
            "D2" : ['d2.png', 2, 2],
            "D3" : ['d3.png', 3, 3],
            "D4" : ['d4.png', 4, 4],
            "D5" : ['d5.png', 5, 5],
            "D6" : ['d6.png', 6, 6],
            "D7" : ['d7.png', 7, 7],
            "D8" : ['d8.png', 8, 8],
            "D9" : ['d9.png', 9, 9],
            "D10" : ['dt.png', 10, 10],
            "DJ" : ['dj.png', 10, 11],
            "DQ" : ['dq.png', 10, 12],
            "DK" : ['dk.png', 10, 13],

            "CA" : ['ca.png', 1, 1],
            "C2" : ['c2.png', 2, 2],
            "C3" : ['c3.png', 3, 3],
            "C4" : ['c4.png', 4, 4],
            "C5" : ['c5.png', 5, 5],
            "C6" : ['c6.png', 6, 6],
            "C7" : ['c7.png', 7, 7],
            "C8" : ['c8.png', 8, 8],
            "C9" : ['c9.png', 9, 9],
            "C10" : ['ct.png', 10, 10],
            "CJ" : ['cj.png', 10, 11],
            "CQ" : ['cq.png', 10, 12],
            "CK" : ['ck.png', 10, 13],

            "HA" : ['ha.png', 1, 1],
            "H2" : ['h2.png', 2, 2],
            "H3" : ['h3.png', 3, 3],
            "H4" : ['h4.png', 4, 4],
            "H5" : ['h5.png', 5, 5],
            "H6" : ['h6.png', 6, 6],
            "H7" : ['h7.png', 7, 7],
            "H8" : ['h8.png', 8, 8],
            "H9" : ['h9.png', 9, 9],
            "H10" : ['ht.png', 10, 10],
            "HJ" : ['hj.png', 10, 11],
            "HQ" : ['hq.png', 10, 12],
            "HK" : ['hk.png', 10, 13]
        }

        this.target = {
            "3" : '타이',
            "1" : '플레이어',
            "2" : '뱅커',
            "4" : '플레이어페어',
            "5" : '뱅커페어',
            "6" : '플레이어보너스',
            "7" : '뱅커보너스',
            "8" : '퍼펙트페어',
            "9" : '이더페어',
            "小" : '소',
            "大" : '대',

            "下注龙赢" : '드래곤',
            "下注龙单" : '드래곤홀',
            "下注龙双" : '드래곤짝',
            "下注龙黑" : '드래곤블랙',
            "下注龙红" : '드래곤레드',
            "下注虎赢" : '타이거',
            "下注虎单" : '타이거홀',
            "下注虎双" : '타이거짝',
            "下注虎黑" : '타이거블랙',
            "下注虎红" : '타이거레드'
        }
    }


    set (data) {
        if (! data) return {code: -1, msg: 'detail 데이터가 존재하지 않습니다.'}

        return this.setGame(data)
    }

    setGame (betData) {
        // console.log("betData->", betData)
        // table
        if (! betData.data) {
            return {code: -1, msg: 'data가 존재하지 않습니다.'}
        }
        /*
                // 카드정보
                if (! detailData.gameResult) {
                    return {code: -1, msg: '카드정보가 존재하지 않습니다.'}
                }
        */


        if(typeof betData.children[0].data.gameresult == "undefined" || !betData.children[0].data.gameresult){
            return false
        }


        if (betData.children[0].data.gameresult.length == 0) {
            return {code: -1, msg: '카드정보가 존재하지 않습니다.'}
        }

        if(betData.gameTypeName.toLowerCase() != "baccarat" && betData.gameTypeName.toLowerCase() != "dragontiger"){
            return false
        }



        const userBets = []





        let result = {} //BANKER, PLAYER, DRAGON, TIGER


        let detail = null

        if (betData.gameTypeName.toLowerCase() == "baccarat") {
            // 바카라, 드래곤타이거, 룰렛, 블랙잭, 식보는 이미지 적용


            result.banker = {}
            result.player = {}
            result.banker.cards = []
            result.player.cards = []

            //결과를 파싱한다.

            //P;C10;DK;S2:B;H6;S6;D10

            let tmp = betData.children[0].data.gameresult[0].split(":")
            let arr1 = tmp[0].split(";")
            let arr2 = tmp[1].split(";")



            for (let i = 1; i < arr1.length; i++) {
                if(arr1[0].toUpperCase() == "P"){
                    result.player.cards.push(arr1[i])
                }
                else if(arr1[0].toUpperCase() == "B"){
                    result.banker.cards.push(arr1[i])
                }
            }


            for (let i = 1; i < arr2.length; i++) {
                if(arr2[0].toUpperCase() == "P"){
                    result.player.cards.push(arr2[i])
                }
                else if(arr2[0].toUpperCase() == "B"){
                    result.banker.cards.push(arr2[i])
                }
            }


            // 뱅커
            let bankerScore = 0
            let bankerCards = []

            // 플레이어
            let playerScore = 0
            let playerCards = []

            for(let key in result.banker.cards) {
                let c = result.banker.cards[key]

                let card = this.cards[c]
                if (key == 2) {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/L_' + card[0])
                } else {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/' + card[0])
                }
                bankerScore += card[1]
            }

            for(let key in result.player.cards) {
                let c = result.player.cards[key]
                let card = this.cards[c]
                if (key == 2) {
                    playerCards.push('https://static.uniongame.org/public/images/cards/L_' + card[0])
                } else {
                    playerCards.push('https://static.uniongame.org/public/images/cards/' + card[0])
                }
                playerScore += card[1]
            }


            bankerScore = bankerScore+""
            bankerScore = bankerScore.charAt(bankerScore.length-1)
            bankerScore = bankerScore * 1

            playerScore = playerScore+""
            playerScore = playerScore.charAt(playerScore.length-1)
            playerScore = playerScore * 1


            if(bankerScore > playerScore) result.outcome = "BANKER"
            else if(playerScore > bankerScore) result.outcome = "PLAYER"
            else if(bankerScore == playerScore) result.outcome = "TIE"
            else result.outcome = "UNKNOWN"

            detail = {
                banker: {
                    cards: bankerCards,
                    score: bankerScore
                },
                player: {
                    cards: playerCards,
                    score: playerScore
                },
            }
        }
        else if (betData.gameTypeName.toLowerCase() == "dragontiger") {
            // 바카라, 드래곤타이거, 룰렛, 블랙잭, 식보는 이미지 적용


            result.dragon = {}
            result.tiger = {}

            //결과를 파싱한다.

            //P;C10;DK;S2:B;H6;S6;D10

            let tmp = betData.children[0].data.gameresult[0].split(":")
            let arr1 = tmp[0].split(";")
            let arr2 = tmp[1].split(";")



            if(arr1[0].toUpperCase() == "D"){
                result.dragon.card = arr1[1]
            }
            else if(arr1[0].toUpperCase() == "T"){
                result.tiger.card = arr1[1]
            }

            if(arr2[0].toUpperCase() == "D"){
                result.dragon.card = arr2[1]
            }
            else if(arr2[0].toUpperCase() == "T"){
                result.tiger.card = arr2[1]
            }




            // 뱅커
            let dragonCardData = this.cards[result.dragon.card]
            let dragonCard = 'https://static.uniongame.org/public/images/cards/' + dragonCardData[0]
            let dragonScore = dragonCardData[2]

            let tigerCardData = this.cards[result.tiger.card]
            let tigerCard = 'https://static.uniongame.org/public/images/cards/' + tigerCardData[0]
            let tigerScore = tigerCardData[2]


            if(dragonScore > tigerScore) result.outcome = "DRAGON"
            else if(tigerScore > dragonScore) result.outcome = "TIGER"
            else if(dragonScore == tigerScore) result.outcome = "TIE"
            else result.outcome = "UNKNOWN"

            detail = {
                dragon: {
                    cards: dragonCard,
                    score: dragonScore
                },
                tiger: {
                    cards: tigerCard,
                    score: tigerScore
                },
            }
        }

        try {
            result.outcome ? result.outcome.toLowerCase() : null
        } catch (e) {
            result.outcome = null
        }

        return {
            code: 0,
            bets: userBets,
            detail: detail,
            result: result.outcome ? result.outcome.toLowerCase() : null
        }
    }
}
