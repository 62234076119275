export default class BotaParser {
    constructor() {


        this.target = {
            "T" : '타이',
            "P" : '플레이어',
            "B" : '뱅커',
            "PP" : '플레이어페어',
            "BP" : '뱅커페어',
            "6" : '플레이어보너스',
            "7" : '뱅커보너스',
            "8" : '퍼펙트페어',
            "9" : '이더페어',
            "小" : '소',
            "大" : '대',

            "下注龙赢" : '드래곤',
            "下注龙单" : '드래곤홀',
            "下注龙双" : '드래곤짝',
            "下注龙黑" : '드래곤블랙',
            "下注龙红" : '드래곤레드',
            "下注虎赢" : '타이거',
            "下注虎单" : '타이거홀',
            "下注虎双" : '타이거짝',
            "下注虎黑" : '타이거블랙',
            "下注虎红" : '타이거레드'
        }
    }


    set (data) {
        if (! data) return {code: -1, msg: 'detail 데이터가 존재하지 않습니다.'}

        return this.setGame(data)
    }

    setGame (betData) {
        // console.log("betData->", betData)
        // table
        if (! betData.data) {
            return {code: -1, msg: 'data가 존재하지 않습니다.'}
        }
        /*
                // 카드정보
                if (! detailData.gameResult) {
                    return {code: -1, msg: '카드정보가 존재하지 않습니다.'}
                }
        if (! betData.data.History) {
            return {code: -1, msg: '배팅정보가 존재하지 않습니다.'}
        }
        */

        if(betData.gameTypeName.toLowerCase() != "baccarat" && betData.gameTypeName.toLowerCase() != "dragontiger"){
            return false
        }

        if(typeof betData.children[0].data.detail.gameResult == "undefined" || !betData.children[0].data.detail.gameResult){
            return false
        }


        const userBets = []


        let betDetail = betData.data.detail
        let resultDetail = betData.children[0].data.detail
        let arrResult = resultDetail.gameResult.split("|")




        if (betData.gameTypeName.toLowerCase() == "baccarat") {


        if(betDetail.P > 0){

            let result = "LOSE"
            if(arrResult[0] == 2) result = "WIN"
            else if(arrResult[0] == 3) result = "DRAW"
            else result = "LOSE"

            userBets.push({
                target: this.target["P"],
                bet: betDetail.P,
                win: 0,
                result:result,
                placedOn: betData.createdAt
            })
        }

        if(betDetail.B > 0){

            let result = "LOSE"
            if(arrResult[0] == 1) result = "WIN"
            else if(arrResult[0] == 3) result = "DRAW"
            else result = "LOSE"

            userBets.push({
                target: this.target["B"],
                bet: betDetail.B,
                win: 0,
                result:result,
                placedOn: betData.createdAt
            })
        }


        if(betDetail.T > 0){

            let result = "LOSE"
            if(arrResult[0] == 3) result = "WIN"
            else result = "LOSE"

            userBets.push({
                target: this.target["T"],
                bet: betDetail.T,
                win: 0,
                result:result,
                placedOn: betData.createdAt
            })
        }



        if(betDetail.PP > 0){

            let result = "LOSE"
            if(arrResult[2] == 1) result = "WIN"
            else result = "LOSE"

            userBets.push({
                target: this.target["PP"],
                bet: betDetail.PP,
                win: 0,
                result:result,
                placedOn: betData.createdAt
            })
        }



        if(betDetail.BP > 0){

            let result = "LOSE"
            if(arrResult[1] == 1) result = "WIN"
            else result = "LOSE"

            userBets.push({
                target: this.target["BP"],
                bet: betDetail.BP,
                win: 0,
                result:result,
                placedOn: betData.createdAt
            })
        }

        }



        return {
            code: 0,
            bets: userBets,
            detail: null,
            result: null
        }
    }
}
