export default class WmParser {
    constructor() {
        this.cards = {
            "♠A" : ['sa.png', 1, 1],
            "♠2" : ['s2.png', 2, 2],
            "♠3" : ['s3.png', 3, 3],
            "♠4" : ['s4.png', 4, 4],
            "♠5" : ['s5.png', 5, 5],
            "♠6" : ['s6.png', 6, 6],
            "♠7" : ['s7.png', 7, 7],
            "♠8" : ['s8.png', 8, 8],
            "♠9" : ['s9.png', 9, 9],
            "♠10" :['st.png', 10, 10],
            "♠J" :['sj.png', 10, 11],
            "♠Q" : ['sq.png', 10, 12],
            "♠K" : ['sk.png', 10, 13],

            "♦A" : ['da.png', 1, 1],
            "♦2" : ['d2.png', 2, 2],
            "♦3" : ['d3.png', 3, 3],
            "♦4" : ['d4.png', 4, 4],
            "♦5" : ['d5.png', 5, 5],
            "♦6" : ['d6.png', 6, 6],
            "♦7" : ['d7.png', 7, 7],
            "♦8" : ['d8.png', 8, 8],
            "♦9" : ['d9.png', 9, 9],
            "♦10" : ['dt.png', 10, 10],
            "♦J" : ['dj.png', 10, 11],
            "♦Q" : ['dq.png', 10, 12],
            "♦K" : ['dk.png', 10, 13],

            "♣A" : ['ca.png', 1, 1],
            "♣2" : ['c2.png', 2, 2],
            "♣3" : ['c3.png', 3, 3],
            "♣4" : ['c4.png', 4, 4],
            "♣5" : ['c5.png', 5, 5],
            "♣6" : ['c6.png', 6, 6],
            "♣7" : ['c7.png', 7, 7],
            "♣8" : ['c8.png', 8, 8],
            "♣9" : ['c9.png', 9, 9],
            "♣10" : ['ct.png', 10, 10],
            "♣J" : ['cj.png', 10, 11],
            "♣Q" : ['cq.png', 10, 12],
            "♣K" : ['ck.png', 10, 13],

            "♥A" : ['ha.png', 1, 1],
            "♥2" : ['h2.png', 2, 2],
            "♥3" : ['h3.png', 3, 3],
            "♥4" : ['h4.png', 4, 4],
            "♥5" : ['h5.png', 5, 5],
            "♥6" : ['h6.png', 6, 6],
            "♥7" : ['h7.png', 7, 7],
            "♥8" : ['h8.png', 8, 8],
            "♥9" : ['h9.png', 9, 9],
            "♥10" : ['ht.png', 10, 10],
            "♥J" : ['hj.png', 10, 11],
            "♥Q" : ['hq.png', 10, 12],
            "♥K" : ['hk.png', 10, 13]
        }

        this.target = {
            "Tie" : '타이',
            "Player" : '플레이어',
            "Banker" : '뱅커',
            "PPair" : '플레이어페어',
            "BPair" : '뱅커페어',
            "完美对子" : '퍼펙트페어',
            "任意对子" : '이더페어',
            "小" : '소',
            "大" : '대',

            "Dragon" : '드래곤',
            "DragonOdd" : '드래곤홀',
            "DragonEven" : '드래곤짝',
            "下注龙黑" : '드래곤블랙',
            "DragonBlack" : '드래곤레드',
            "Tiger" : '타이거',
            "TigerOdd" : '타이거홀',
            "TigerEven" : '타이거짝',
            "TigerBlack" : '타이거블랙',
            "TigerRed" : '타이거레드'
        }
    }

    set (data) {
        if (! data) return {code: -1, msg: 'detail 데이터가 존재하지 않습니다.'}

        return this.setGame(data)
    }

    setGame (betData) {
        let detailData = betData.detail
        // table
        if (! detailData) {
            return {code: -1, msg: 'detail.data가 존재하지 않습니다.'}
        }

        // 카드정보
        if (! detailData.gameResult) {
            return {code: -1, msg: '카드정보가 존재하지 않습니다.'}
        }

        if (! betData.data.betdetail) {
            return {code: -1, msg: '배팅정보가 존재하지 않습니다.'}
        }


        const userBets = []


        let betDetail = JSON.parse(betData.data.betdetail)
        for(let key in betDetail) {
            let betAmount = Math.abs(betDetail[key])
            let betTarget = this.target[key]

            let winAmount
            if (betData.children.length > 0) {
                let winDetail = JSON.parse(betData.children[0].data.betdetail)
                winAmount = winDetail[key]
            }
            else{
                winAmount = 0
            }
            userBets.push({
                target: betTarget,
                bet: betAmount,
                win: winAmount,
                placedOn: detailData.betTime
            })



        }


        /*
        for(let key in detailParticipantsData) {
            const part = detailParticipantsData[key]
            const bets = part.bets
            for(let key2 in bets) {
                const bet = bets[key2]

                let betTarget = bet.description

                let betAmount = parseFloat(bet.stake)
                let winAmount = parseFloat(bet.payout)

                userBets.push({
                    target: betTarget,
                    bet: betAmount,
                    win: winAmount,
                    placedOn: bet.placedOn,
                    id: bet.transactionId
                })
            }
        }

         */







        let result = {} //BANKER, PLAYER, DRAGON, TIGER


        let detail = null

        if (detailData.gname.toLowerCase() == "baccarat") {
            // 바카라, 드래곤타이거, 룰렛, 블랙잭, 식보는 이미지 적용


            result.banker = {}
            result.player = {}
            result.banker.cards = []
            result.player.cards = []

            //결과를 파싱한다.
            //Banker:♣5♦10 Player:♠J♥A♦9
            let tmp = detailData.gameResult.split(" ")
            let tmp_area1 = tmp[0].split(":")
            let tmp_area2 = tmp[1].split(":")


            //첫번째 부터 파싱

            let buffer = ''
            for (let i = 0; i < tmp_area1[1].length; i++) {
                let str = tmp_area1[1].charAt(i)
                if(i > 0 && (str == "♣" || str == "♦" || str == "♠" || str == "♥" || (i+1) == tmp_area1[1].length) ) {
                    //다음문자열이 문양인경우, 한개의 카드가 끝났다.

                    if((i+1) == tmp_area1[1].length) buffer += str //마지막문자열이면 추가

                    if(tmp_area1[0].toLowerCase() == "banker"){
                        result.banker.cards.push(buffer)
                    }
                    else{
                        result.player.cards.push(buffer)
                    }
                    buffer = str
                }
                else{
                    buffer += str
                }
            }

            buffer = ''
            for (let i = 0; i < tmp_area2[1].length; i++) {
                let str = tmp_area2[1].charAt(i)
                if(i > 0 && (str == "♣" || str == "♦" || str == "♠" || str == "♥" || (i+1) == tmp_area2[1].length) ) {
                    //다음문자열이 문양인경우, 한개의 카드가 끝났다.

                    if((i+1) == tmp_area2[1].length) buffer += str //마지막문자열이면 추가
                    if(tmp_area2[0].toLowerCase() == "banker"){
                        result.banker.cards.push(buffer)
                    }
                    else{
                        result.player.cards.push(buffer)
                    }
                    buffer = str
                }
                else{
                    buffer += str
                }
            }


            // 뱅커
            let bankerScore = 0
            let bankerCards = []

            // 플레이어
            let playerScore = 0
            let playerCards = []

            for(let key in result.banker.cards) {
                let c = result.banker.cards[key]

                let card = this.cards[c]
                if (key == 2) {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/L_' + card[0])
                } else {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/' + card[0])
                }
                bankerScore += card[1]
            }

            for(let key in result.player.cards) {
                let c = result.player.cards[key]
                let card = this.cards[c]
                if (key == 2) {
                    playerCards.push('https://static.uniongame.org/public/images/cards/L_' + card[0])
                } else {
                    playerCards.push('https://static.uniongame.org/public/images/cards/' + card[0])
                }
                playerScore += card[1]
            }


            bankerScore = bankerScore+""
            bankerScore = bankerScore.charAt(bankerScore.length-1)
            bankerScore = bankerScore * 1

            playerScore = playerScore+""
            playerScore = playerScore.charAt(playerScore.length-1)
            playerScore = playerScore * 1


            if(bankerScore > playerScore) result.outcome = "BANKER"
            else if(playerScore > bankerScore) result.outcome = "PLAYER"
            else if(bankerScore == playerScore) result.outcome = "TIE"
            else result.outcome = "UNKNOWN"

            detail = {
                banker: {
                    cards: bankerCards,
                    score: bankerScore
                },
                player: {
                    cards: playerCards,
                    score: playerScore
                },
            }
        } else if (detailData.gname.toLowerCase() == "Dragon&Tiger") {



            result.dragon = {}
            result.tiger = {}


            //결과를 파싱한다.
            //Dragon:♦5 Tiger:♦9
            let tmp = detailData.gameResult.split(" ")
            let tmp_area1 = tmp[0].split(":")
            let tmp_area2 = tmp[0].split(":")


            //첫번째 부터 파싱
            if(tmp_area1[0].toLowerCase() == "dragon"){
                result.dragon.cards = tmp_area1[1]
            }
            else{
                result.tiger.cards = tmp_area1[1]
            }

            //첫번째 부터 파싱
            if(tmp_area2[0].toLowerCase() == "dragon"){
                result.dragon.cards = tmp_area2[1]
            }
            else{
                result.tiger.cards = tmp_area2[1]
            }



            // 드래곤
            let dragonCard = this.cards[result.dragon.card]
            dragonCard = 'https://static.uniongame.org/public/images/cards/' + dragonCard[0]
            let dragonScore = dragonCard[1]

            // 타이거
            let tigerCard = this.cards[result.tiger.card]
            tigerCard = 'https://static.uniongame.org/public/images/cards/' + tigerCard[0]
            let tigerScore = tigerCard[1]


            if(dragonScore > tigerScore) result.outcome = "DRAGON"
            else if(tigerScore > dragonScore) result.outcome = "TIGER"
            else if(dragonScore == tigerScore) result.outcome = "TIE"
            else result.outcome = "UNKNOWN"

            detail = {
                dragon: {
                    card: dragonCard,
                    score: dragonScore
                },
                tiger: {
                    card: tigerCard,
                    score: tigerScore
                },
            }
        }

        try {
            result.outcome ? result.outcome.toLowerCase() : null
        } catch (e) {
            result.outcome = null
        }

        return {
            code: 0,
            bets: userBets,
            detail: detail,
            result: result.outcome ? result.outcome.toLowerCase() : null
        }
    }
}
