<template>
  <span class="badge bg-danger p-1" v-if="target === 'BC_BANKER'">Banker</span>
  <span class="badge bg-primary p-1" v-if="target === 'BC_PLAYER'">Player</span>
    <span class="badge bg-danger p-1" v-if="target === 'BC_BANKER_TIGER'">Banker_T</span>
    <span class="badge bg-primary p-1" v-if="target === 'BC_PLAYER_TIGER'">Player_T</span>
    <span class="badge bg-danger p-1" v-if="target === 'BC_BANKER_NO_COMMISSION'">Banker_NC</span>
    <span class="badge bg-primary p-1" v-if="target === 'BC_PLAYER_NO_COMMISSION'">Player_NC</span>
  <span class="badge bg-success p-1" v-if="target === 'BC_TIE'">Tie</span>
  <span class="badge badge-soft-primary p-1" v-if="target === 'BC_PLAYER_PAIR'">Player Pair</span>
  <span class="badge badge-soft-danger p-1" v-if="target === 'BC_BANKER_PAIR'">Banker Pair</span>
  <span class="badge badge-soft-pink p-1" v-if="target === 'perfect pair'">Perfect Pair</span>
  <span class="badge badge-soft-secondary p-1" v-if="target === 'either pair'">Either Pair</span>
</template>

<script>
export default {
  props: {
    target: String
  },
  methods: {

  },
  computed: {

  }
}
</script>
