export default class BGParser {
    constructor() {
        this.cards = {
            "portrait/c0001" : 'sa.png',
            "portrait/c0002" : 's2.png',
            "portrait/c0003" : 's3.png',
            "portrait/c0004" : 's4.png',
            "portrait/c0005" : 's5.png',
            "portrait/c0006" : 's6.png',
            "portrait/c0007" : 's7.png',
            "portrait/c0008" : 's8.png',
            "portrait/c0009" : 's9.png',
            "portrait/c0010" : 'st.png',
            "portrait/c0011" : 'sj.png',
            "portrait/c0012" : 'sq.png',
            "portrait/c0013" : 'sk.png',

            "portrait/c0014" : 'ha.png',
            "portrait/c0015" : 'h2.png',
            "portrait/c0016" : 'h3.png',
            "portrait/c0017" : 'h4.png',
            "portrait/c0018" : 'h5.png',
            "portrait/c0019" : 'h6.png',
            "portrait/c0020" : 'h7.png',
            "portrait/c0021" : 'h8.png',
            "portrait/c0022" : 'h9.png',
            "portrait/c0023" : 'ht.png',
            "portrait/c0024" : 'hj.png',
            "portrait/c0025" : 'hq.png',
            "portrait/c0026" : 'hk.png',

            "portrait/c0027" : 'ca.png',
            "portrait/c0028" : 'c2.png',
            "portrait/c0029" : 'c3.png',
            "portrait/c0030" : 'c4.png',
            "portrait/c0031" : 'c5.png',
            "portrait/c0032" : 'c6.png',
            "portrait/c0033" : 'c7.png',
            "portrait/c0034" : 'c8.png',
            "portrait/c0035" : 'c9.png',
            "portrait/c0036" : 'ct.png',
            "portrait/c0037" : 'cj.png',
            "portrait/c0038" : 'cq.png',
            "portrait/c0039" : 'ck.png',

            "portrait/c0040" : 'da.png',
            "portrait/c0041" : 'd2.png',
            "portrait/c0042" : 'd3.png',
            "portrait/c0043" : 'd4.png',
            "portrait/c0044" : 'd5.png',
            "portrait/c0045" : 'd6.png',
            "portrait/c0046" : 'd7.png',
            "portrait/c0047" : 'd8.png',
            "portrait/c0048" : 'd9.png',
            "portrait/c0049" : 'dt.png',
            "portrait/c0050" : 'dj.png',
            "portrait/c0051" : 'dq.png',
            "portrait/c0052" : 'dk.png'


        }

        this.target = {
            "下注和" : '타이',
            "闲赢" : '플레이어',
            "庄赢" : '뱅커',
            "闲对" : '플레이어페어',
            "庄对" : '뱅커페어',
            "完美对子" : '퍼펙트페어',
            "任意对子" : '이더페어',
            "小" : '소',
            "大" : '대',

            "下注龙赢" : '드래곤',
            "下注龙单" : '드래곤홀',
            "下注龙双" : '드래곤짝',
            "下注龙黑" : '드래곤블랙',
            "下注龙红" : '드래곤레드',
            "下注虎赢" : '타이거',
            "下注虎单" : '타이거홀',
            "下注虎双" : '타이거짝',
            "下注虎黑" : '타이거블랙',
            "下注虎红" : '타이거레드'
        }
    }

    set (data) {
        if (! data) return {code: -1, msg: 'detail 데이터가 존재하지 않습니다.'}

        return this.setGame(data)
    }

    setGame (detailData) {
        // table
        if (! detailData) {
            return {code: -1, msg: 'detail.data가 존재하지 않습니다.'}
        }

        // 카드정보
        if (! detailData.resultMasterPics || ! detailData.resultCluserPics) {
            return {code: -1, msg: '카드정보가 존재하지 않습니다.'}
        }

        if (! detailData.bettingTypeName) {
            return {code: -1, msg: '배팅정보가 존재하지 않습니다.'}
        }


        const userBets = []

        let betTarget = this.target[detailData.bettingTypeName]

        let betAmount = parseFloat(detailData.amount)
        let winAmount = parseFloat(detailData.payment)

        userBets.push({
            target: betTarget,
            bet: betAmount,
            win: winAmount,
            placedOn: detailData.time,
            id: detailData.orderId
        })
        /*
        for(let key in detailParticipantsData) {
            const part = detailParticipantsData[key]
            const bets = part.bets
            for(let key2 in bets) {
                const bet = bets[key2]

                let betTarget = bet.description

                let betAmount = parseFloat(bet.stake)
                let winAmount = parseFloat(bet.payout)

                userBets.push({
                    target: betTarget,
                    bet: betAmount,
                    win: winAmount,
                    placedOn: bet.placedOn,
                    id: bet.transactionId
                })
            }
        }

         */







        let result = {} //BANKER, PLAYER, DRAGON, TIGER

        let detail = null

        if (detailData.gameType == 1 || detailData.gameType == 10) {   // 1 = 바카라, 10 = 스퀴즈 바카라, 11 = 컬러바카라(분석필요)
            // 바카라, 드래곤타이거, 룰렛, 블랙잭, 식보는 이미지 적용


            result.banker = {}
            result.player = {}


            //결과를 파싱한다.
            //庄赢 (庄:9,闲:4)
            let tmp = detailData.fmtBetResult.split(" ")
            let tmp_result = tmp[0]

            if(tmp_result == "闲赢")  result.outcome = "PLAYER"
            else if(tmp_result == "庄赢")  result.outcome = "BANKER"
            else if(tmp_result == "和局")  result.outcome = "TIE"
            else  result.outcome = "UNDEFINED"


            tmp[1] = tmp[1].replace("(", "").replace(")", "")
            let tmp_score = tmp[1].split(",")

            // console.log("tmp_score[0]", tmp_score[0])
            // console.log("tmp_score[1]", tmp_score[1])
            let tmp_score_front = tmp_score[0].split(":")
            let tmp_score_back = tmp_score[1].split(":")

            if(tmp_score_front[0] == '庄' && tmp_score_back[0] == '闲') {
                result.banker.score = tmp_score_front[1]
                result.player.score = tmp_score_back[1]

                result.banker.cards = detailData.resultMasterPics
                result.player.cards = detailData.resultCluserPics
            }
            else{
                result.player.score = tmp_score_front[1]
                result.banker.score = tmp_score_back[1]

                result.player.cards = detailData.resultMasterPics
                result.banker.cards = detailData.resultCluserPics

            }






            // 뱅커
            let bankerScore = result.banker.score
            let bankerCards = []

            // 플레이어
            let playerScore = result.player.score
            let playerCards = []

            for(let key in result.banker.cards) {
                let c = result.banker.cards[key]
                let card = this.cards[c.replace("landscape", "portrait")]
                if (key == 2) {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/L_' + card)
                } else {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/' + card)
                }
            }

            for(let key in result.player.cards) {
                let c = result.player.cards[key]
                let card = this.cards[c.replace("landscape", "portrait")]
                if (key == 2) {
                    playerCards.push('https://static.uniongame.org/public/images/cards/L_' + card)
                } else {
                    playerCards.push('https://static.uniongame.org/public/images/cards/' + card)
                }
            }

            detail = {
                banker: {
                    cards: bankerCards,
                    score: bankerScore
                },
                player: {
                    cards: playerCards,
                    score: playerScore
                },
            }

        } else if (detailData.gameType == 4) {  //4 = 드래곤타이거


            result.dragon = {}
            result.tiger = {}


            //결과를 파싱한다.
            //虎赢 (龙:4,虎:12)
            let tmp = detailData.fmtBetResult.split(" ")
            let tmp_result = tmp[0]

            if(tmp_result == "龙赢")  result.outcome = "DRAGON"
            else if(tmp_result == "虎赢")  result.outcome = "TIGER"
            else if(tmp_result == "和局")  result.outcome = "TIE"
            else  result.outcome = "UNDEFINED"


            tmp[1] = tmp[1].replace("(", "").replace(")", "")
            let tmp_score = tmp[1].split(",")

            let tmp_score_front = tmp_score[0].split(":")
            let tmp_score_back = tmp_score[1].split(":")

            if(tmp_score_front[0] == '龙' && tmp_score_back[0] == '虎') {
                result.dragon.score = tmp_score_front[1]
                result.tiger.score = tmp_score_back[1]

                result.dragon.cards = detailData.resultMasterPics
                result.tiger.cards = detailData.resultCluserPics
            }
            else{
                result.tiger.score = tmp_score_front[1]
                result.dragon.score = tmp_score_back[1]

                result.tiger.cards = detailData.resultMasterPics
                result.dragon.cards = detailData.resultCluserPics

            }

            // 드래곤
            let dragonScore = result.dragon.score
            let dragonCard = this.cards[result.dragon.card]
            dragonCard = 'https://static.uniongame.org/public/images/cards/' + dragonCard

            // 타이거
            let tigerScore = result.tiger.score
            let tigerCard = this.cards[result.tiger.card]
            tigerCard = 'https://static.uniongame.org/public/images/cards/' + tigerCard

            detail = {
                dragon: {
                    card: dragonCard,
                    score: dragonScore
                },
                tiger: {
                    card: tigerCard,
                    score: tigerScore
                },
            }
        }

        try {
            result.outcome ? result.outcome.toLowerCase() : null
        } catch (e) {
            result.outcome = null
        }

        return {
            code: 0,
            bets: userBets,
            detail: detail,
            result: result.outcome ? result.outcome.toLowerCase() : null
        }
    }
}
