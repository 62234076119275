export default class EvolutionParser {
    constructor() {
        this.cards = {
            "AS" : 'sa.png',
            "2S" : 's2.png',
            "3S" : 's3.png',
            "4S" : 's4.png',
            "5S" : 's5.png',
            "6S" : 's6.png',
            "7S" : 's7.png',
            "8S" : 's8.png',
            "9S" : 's9.png',
            "TS" : 'st.png',
            "JS" : 'sj.png',
            "QS" : 'sq.png',
            "KS" : 'sk.png',

            "AD" : 'da.png',
            "2D" : 'd2.png',
            "3D" : 'd3.png',
            "4D" : 'd4.png',
            "5D" : 'd5.png',
            "6D" : 'd6.png',
            "7D" : 'd7.png',
            "8D" : 'd8.png',
            "9D" : 'd9.png',
            "TD" : 'dt.png',
            "JD" : 'dj.png',
            "QD" : 'dq.png',
            "KD" : 'dk.png',

            "AC" : 'ca.png',
            "2C" : 'c2.png',
            "3C" : 'c3.png',
            "4C" : 'c4.png',
            "5C" : 'c5.png',
            "6C" : 'c6.png',
            "7C" : 'c7.png',
            "8C" : 'c8.png',
            "9C" : 'c9.png',
            "TC" : 'ct.png',
            "JC" : 'cj.png',
            "QC" : 'cq.png',
            "KC" : 'ck.png',

            "AH" : 'ha.png',
            "2H" : 'h2.png',
            "3H" : 'h3.png',
            "4H" : 'h4.png',
            "5H" : 'h5.png',
            "6H" : 'h6.png',
            "7H" : 'h7.png',
            "8H" : 'h8.png',
            "9H" : 'h9.png',
            "TH" : 'ht.png',
            "JH" : 'hj.png',
            "QH" : 'hq.png',
            "KH" : 'hk.png'
        }
    }

    set (data) {
        if (! data) return {code: -1, msg: 'detail 데이터가 존재하지 않습니다.'}

        return this.setGame(data)
    }

    setGame (detailData) {
        // table
        if (! detailData.table) {
            return {code: -1, msg: 'detail.data가 존재하지 않습니다.'}
        }

        const detailTableData = detailData.table
        if (! detailTableData) {
            return {code: -1, msg: 'detail.data.table이 존재하지 않습니다.'}
        }

        // 배팅내역
        if (! detailData.participants) {
            return {code: -1, msg: 'detail.data.participants가 존재하지 않습니다.'}
        }

        const detailParticipantsData = detailData.participants
        if (! detailParticipantsData) {
            return {code: -1, msg: 'detail.data.participants이 존재하지 않습니다.'}
        }

        const userBets = []
        for(let key in detailParticipantsData) {
            const part = detailParticipantsData[key]
            const bets = part.bets
            for(let key2 in bets) {
                const bet = bets[key2]

                /*
                if (bet.code.indexOf('BAC_') === -1) {
                    return {code: -1, msg: '배팅 내역이 바카라가 아닙니다.'}
                }
                 */

                //let betTarget = bet.code.slice(4, bet.code.length)
                //betTarget = betTarget.toLowerCase()
                let betTarget = bet.description

                let betAmount = parseFloat(bet.stake)
                let winAmount = parseFloat(bet.payout)

                userBets.push({
                    target: betTarget,
                    bet: betAmount,
                    win: winAmount,
                    placedOn: bet.placedOn,
                    id: bet.transactionId
                })
            }
        }

        // 결과
        if (! detailData.result) {
            return {code: -1, msg: 'detail.data.result가 존재하지 않습니다.'}
        }

        let result = detailData.result

        let detail = null

        if (detailData.gameType === 'baccarat') {
            // 바카라, 드래곤타이거, 룰렛, 블랙잭, 식보는 이미지 적용
            // 뱅커
            let bankerScore = result.banker.score
            let bankerCards = []

            // 플레이어
            let playerScore = result.player.score
            let playerCards = []

            for(let key in result.banker.cards) {
                let c = result.banker.cards[key]
                let card = this.cards[c]
                if (key == 2) {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/L_' + card)
                } else {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/' + card)
                }
            }

            for(let key in result.player.cards) {
                let c = result.player.cards[key]
                let card = this.cards[c]
                if (key == 2) {
                    playerCards.push('https://static.uniongame.org/public/images/cards/L_' + card)
                } else {
                    playerCards.push('https://static.uniongame.org/public/images/cards/' + card)
                }
            }

            detail = {
                banker: {
                    cards: bankerCards,
                    score: bankerScore
                },
                player: {
                    cards: playerCards,
                    score: playerScore
                },
            }

        } else if (detailData.gameType === 'dragontiger') {
            // 드래곤
            let dragonScore = result.dragon.score
            let dragonCard = this.cards[result.dragon.card]
            dragonCard = 'https://static.uniongame.org/public/images/cards/' + dragonCard

            // 타이거
            let tigerScore = result.tiger.score
            let tigerCard = this.cards[result.tiger.card]
            tigerCard = 'https://static.uniongame.org/public/images/cards/' + tigerCard

            detail = {
                dragon: {
                    card: dragonCard,
                    score: dragonScore
                },
                tiger: {
                    card: tigerCard,
                    score: tigerScore
                },
            }
        }

        try {
            result.outcome ? result.outcome.toLowerCase() : null
        } catch (e) {
            result.outcome = null
        }

        return {
            code: 0,
            bets: userBets,
            detail: detail,
            result: result.outcome ? result.outcome.toLowerCase() : null
        }
    }
}
