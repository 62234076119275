<script>
import Detail from './detail'
import DatePicker from "@vuepic/vue-datepicker"
import PartnerTreeMenu from '@/components/partner/tree-menu'
import PartnerBadge from '../badge-level'
import BadgeSeamless from "@/components/badge-seamless"
import BadgeTransactionType from "@/components/badge-transaction-type"
import BadgeTransactionCategory from "@/components/badge-transaction-category"
import BadgeGameCategory from "@/components/badge-game-category"
import BadgeGameTarget from "@/components/badge-game-target"
import EvolutionParser from "@/libraries/vendor/evolution-v3"
import BGParser from "@/libraries/vendor/bg-v3"
import AGParser from "@/libraries/vendor/ag-v3"
import BotaParser from "@/libraries/vendor/bota-v3"
import VivoParser from "@/libraries/vendor/vivo-v3"
import WmParser from "@/libraries/vendor/wm-v3"
//import SelectVendors from "@/components/select-vendors"
import SelectGames from "@/components/select-games"

export default {
  props: {
    user:Object,
    searchByUser:Boolean
  },
  components: {
    Detail,
    DatePicker,
    PartnerTreeMenu,
    PartnerBadge,
    BadgeSeamless,
    BadgeTransactionType,
    BadgeTransactionCategory,
    BadgeGameCategory,
    BadgeGameTarget,
    //SelectVendors,
    SelectGames
  },
  data() {
    return {
      search: {
        groupKey: '',
        searchType: 'username',
        searchText: '',
        method: 'game',
        category: 'turn',
        type: 'bet',
        vendor: '',
        game: '',
        seamlessState: '',
        beginDate: '',
        endDate: '',
        parent: null
      },
      sort: { createdAt: -1 },
      list: [],
      limit: 50,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1, // 현재 페이지

      vendors: [],
      games: [],
      evolutionParser: new EvolutionParser(),
      bgParser: new BGParser(),
      agParser: new AGParser(),
      botaParser: new BotaParser(),
      vivoParser: new VivoParser(),
      wmParser: new WmParser()
    }
  },
  created() {

  },
  mounted() {
    let beginDate = new Date()
    beginDate.setDate(beginDate.getDate() - 1)
    beginDate.setHours(0)
    beginDate.setMinutes(0)
    beginDate.setSeconds(0)
    // let endDate = new Date()

    this.search.beginDate = beginDate
    // this.search.endDate = endDate

    const a = localStorage.getItem('limit')
    this.limit = (a !== 'NaN' && a) ? parseInt(a) : 50

    this.search.parent = this.parent ? this.parent.id : null

    if(this.searchByUser == true) {
      if(this.user.username) this.search.searchText = this.user.username
      this.searchList().then()
    }
    else this.$refs.partnerTreeMenu.getPartners().then()
  },
  methods: {
    setParent: function(id) {
      this.search.parent = id
    },
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      this.searchList().then()
    },
    goFirst() {
      this.page = 1
    },
    goPre() {
      if (this.page === 1) {
        this.page = 1
      } else {
        this.page -= 1
      }
    },
    goNext() {
      this.page += 1
    },
    searchByPage: function(page) {
      this.page = page
    },
    searchList: async function() {

      const loader = this.$loading.show()
      try {
        localStorage.setItem('limit', this.limit)
        const params = {
          search: { method: this.search.method, category: this.search.category, type: this.search.type, vendor: this.search.vendor, game: this.search.game, seamlessState: this.search.seamlessState, parent: this.search.parent },
          // search: { method: this.search.method, category: this.search.category, type: this.search.type, vendor: 96, game: this.search.game, seamlessState: this.search.seamlessState, parent: this.search.parent },
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText

        const result = await this.$API.transaction.games(params)

        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        for(let key in result.documents) {
          let doc = result.documents[key]
          if (doc.detail) {
            if (doc.vendorKey === 'evolution_casino') {
              doc.info = this.evolutionParser.set(doc.detail)
            }
            else if(doc.vendorKey === 'bg_casino'){
              doc.info = this.bgParser.set(doc.detail)
            }
            else if(doc.vendorKey === 'wm_casino'){
              doc.info = this.wmParser.set(doc)
            }
            doc.showDetail = false
          }
          if(doc.vendorKey === 'ag_casino' && doc.children.length > 0){
            doc.info = this.agParser.set(doc)
          }
          else if(doc.vendorKey === 'bota_casino' && doc.children.length > 0){
            doc.info = this.botaParser.set(doc)
          }
          else if(doc.vendorKey === 'vivo_casino' && doc.children.length > 0){
            doc.info = this.vivoParser.set(doc)
          }

          this.list.push(doc)
        }

        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },

    searchVendors(id) {
      this.search.vendor = id
      this.$refs.refSearchGames.getGames(id)
    },

    selectGames(id) {
      this.search.game = id
    },

    // 전송 데이터
    openData: function(data) {
      this.$refs['modal-detail'].open(data)
    },

    // 에볼, 프라그마틱, 드림게이밍 등 상세정보
    async openDetail(record) {
      // console.log("record.companyKey->", record.companyKey)
      if (record.companyKey === 'pragmatic') {
        const result = await this.$API.transaction.getPragmaticLink(record.transactionKey)
        if (result.code !== 0) {
          return alert(result.msg)
        }
        window.open(result.url)
      }
      else if (record.companyKey === 'fghub' || record.companyKey === 'cq9' || record.vendorKey === 'mtv_casino') {
        const result = await this.$API.transaction.getDetailLink(record.transactionKey)
        if (result.code !== 0) {
          return alert(result.msg)
        }
        window.open(result.url)
      }
      else if (record.vendorKey === 'oriental_casino') {
        window.open(record.children[0].data.result_url)
      }
      else if(record.vendorKey === 'bg_casino') {
        this.$refs['modal-detail'].open(record, 'detailResultBG')
      }
      else if(record.vendorKey === 'wm_casino') {
        this.$refs['modal-detail'].open(record, 'detailResultWM')
      }
      else if(record.vendorKey === 'vivo_casino') {
        this.$refs['modal-detail'].open(record, 'detailResultVIVO')
      }
      else if(record.vendorKey === 'ag_casino') {
        this.$refs['modal-detail'].open(record, 'detailResultAG')
      }
      else if(record.vendorKey === 'bota_casino') {
        // console.log("record->", record)
        this.$refs['modal-detail'].open(record, 'detailResultBota')
      }
      else if(record.vendorKey === 'evolution_casino') {
        this.$refs['modal-detail'].open(record, 'detailResultEvolution')
      }
      else {
        // 에볼등 게임 상세내역 화면
        this.$refs['modal-detail'].open(record, 'detailResult')
      }
    },
    async openSeamlessLog(record) {
      this.$refs['modal-detail'].open(record, 'detailSeamless')
    }
  },
  watch: {
    page: function() {
      this.searchList().then()
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <form class="header-search" @submit.prevent="searchList">

      <!-- 검색 //-->
      <div class="row">
        <div class="col-md-6" v-if="searchByUser != true"></div>
        <!--
        <div class="form-group col-md-2 mb-2">
          <select-vendors @searchVendors="searchVendors"></select-vendors>
        </div>
        -->

        <div class="form-group col-md-2 mb-2">
          <select-games @selectGames="selectGames" ref="refSearchGames"></select-games>
        </div>

        <div class="form-group col-md-1 mb-2">
          <select class="form-select" v-model="search.seamlessState">
            <option value="">{{ $t('심리스 결과') }}</option>
            <option value="finish">완료</option>
            <option value="error">오류</option>
          </select>
        </div>

        <div class="form-group col-md-1 mb-2">
          <select class="form-select" v-model="search.searchType">
            <option value="username">{{ $t('회원아이디') }}</option>
            <option value="siteUsername">{{ $t('업체 아이디') }}</option>
            <option value="parentTransactionKey">{{ $t('코드') }}</option>
          </select>
        </div>

        <div class="form-group col-md-2 mb-2">
          <input type="text" class="form-control" v-model="search.searchText" :placeholder="$t('검색어')" />
        </div>
      </div>

      <!-- 페이징 및 검색 //-->
      <div class="row">
        <div class="col-md-3">
          <div class="form-group mb-2">
            <DatePicker
                v-model="search.beginDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :close-on-auto-apply="false"
                :placeholder="$t('검색') +  $t('시작') +  $t('일시')"
            ></DatePicker>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group mb-2">
            <DatePicker
                v-model="search.endDate"
                :format="'yyyy-MM-dd HH:mm:ss'"
                utc
                auto-apply
                :close-on-auto-apply="false"
                :placeholder="$t('검색') +  $t('종료') +  $t('일시')"
            ></DatePicker>
          </div>
        </div>
        <div class="col-md-2 mb-2">
          <select class="form-select" v-model="limit">
            <option :value="10">{{ $t('10개') }}</option>
            <option :value="25">{{ $t('25개') }}</option>
            <option :value="50">{{ $t('50개') }}</option>
            <option :value="100">{{ $t('100개') }}</option>
          </select>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <div class="input-group mb-2">
              <button type="button" class="btn btn-outline-success" @click="goFirst">{{ $t('처음') }}</button>
              <button type="button" class="btn btn-outline-primary" @click="goPre">{{ $t('이전') }}</button>
              <input type="number" class="form-control" min="1" v-model="page" />
              <button type="button" class="btn btn-outline-info" @click="goNext">{{ $t('다음') }}</button>
            </div>
          </div>
        </div>
        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-primary w-100">{{ $t('검색') }}</button>
        </div>
      </div>
    </form>

    <hr class="mt-0 mb-3" />

    <div class="w-100">
      <div class="d-md-flex">

        <!-- 상위 목록 //-->
        <partner-tree-menu :partner="$store.getters['auth/user']" @setParent="setParent" @searchList="searchList" ref="partnerTreeMenu" v-if="searchByUser != true"></partner-tree-menu>

        <!-- 목록 //-->

        <div id="table-wrapper" class="table-responsive mb-0 rounded-3 w-100" style="overflow: auto;">
          <div class="w-100">
            <div class="card">
              <div class="card-body">
                <!-- 리스트 //-->

                  <table class="table align-middle table-sm table-nowrap sticky-table table-striped-columns">
                    <thead class="table-light">
                    <tr>
                      <th>
                        <a href="javascript:void(0)" @click="setSort('createdAt')">#</a>&nbsp;
                        <template v-if="sort.createdAt"><span class="fa fa-sort-amount-down" v-if="sort.createdAt === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.createdAt === 1"></span></template>
                      </th>
                      <th>{{ $t('상위') }}{{ $t('정보') }}</th>
                        <th>{{ $t('회원아이디') }}</th>
                        <th>{{ $t('유형') }}</th>
                        <th>{{ $t('게임사') }}</th>
                        <th>{{ $t('게임유형') }}</th>
                        <th>{{ $t('게임형식') }}</th>
                        <th>{{ $t('게임이름') }}</th>
                        <th>{{ $t('형식') }}</th>
                        <th>{{ $t('이전')}}</th>
                        <th>{{ $t('배팅') }}</th>
                        <th>{{ $t('결과') }}</th>
                        <th>{{ $t('이후')}}</th>
                      <th>
                        <a href="javascript:void(0)" @click="setSort('createdAt')">{{ $t('시간') }}</a>&nbsp;
                        <template v-if="sort.createdAt"><span class="fa fa-sort-amount-down" v-if="sort.createdAt === -1"></span><span class="fa fa-sort-amount-up" v-if="sort.createdAt === 1"></span></template>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                      <template v-for="record in list" :key="record.id">
                        <tr :class="{'border-bottom border-5': record.children.length === 0, 'bg-danger bg-soft': record.seamlessState === 'error'}">
                          <!-- 번호 //-->
                          <td :rowspan="record.children.length + 1">
                            <!-- 디테일 표시 -->
                            <!--


                            공통 필수조건. seamlessState = finish 이어야함

                            조건1. info(디테일 데이터)가 존재하는경우
                            조건2. 조건없이 없이 출력 (현재 pragmatic, fghub)
                            조건3. 결과 콜백이 있어야만 출력 (현재 cq9, vivo_casino, ag_casino, bota_casino, oriental_casino)
                            -->
                            <span class="badge badge-soft-success ms-1" style="cursor: pointer;" v-if="(record.seamlessState === 'finish') && ((record.info && record.info.code === 0) || (record.companyKey === 'pragmatic' || record.companyKey === 'fghub') ||(record.children.length > 0 && (record.vendorKey === 'mtv_casino' || record.companyKey === 'cq9' || record.vendorKey === 'vivo_casino' || record.vendorKey === 'ag_casino' || record.vendorKey === 'bota_casino' || record.vendorKey === 'oriental_casino' ) ) )" @click="openDetail(record)" v-b-tooltip.hover data-placement="top" :title="'상세내역'"><i class="fa fa-link"></i></span>

                          </td>
                          <!-- 상위정보 //-->
                          <td :rowspan="record.children.length + 1" class="text-start">
                            <router-link :to="'/partner/detail/' + record.top.id" target="_blank" v-if="record.top && record.top.id !== record.user.id && ($store.getters['auth/user'].level != 'super' && $store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.top" :show-name="true"></partner-badge></router-link>
                            <router-link :to="'/partner/detail/' + record['super'].id" target="_blank" v-if="record['super'] && record['super'].id !== record.user.id && ($store.getters['auth/user'].level != 'master' && $store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.super" :show-name="true"></partner-badge></router-link>
                            <router-link :to="'/partner/detail/' + record.master.id" target="_blank" v-if="record.master && record.master.id !== record.user.id && ($store.getters['auth/user'].level != 'agent' && $store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.master" :show-name="true"></partner-badge></router-link>
                            <router-link :to="'/partner/detail/' + record.agent.id" target="_blank" v-if="record.agent && record.agent.id !== record.user.id && ($store.getters['auth/user'].level != 'shop')"><partner-badge :partner="record.agent" :show-name="true"></partner-badge></router-link>
                            <router-link :to="'/partner/detail/' + record.shop.id" target="_blank" v-if="record.shop && record.shop.id !== record.user.id"><partner-badge :partner="record.shop" :show-name="true"></partner-badge></router-link>
                          </td>
                          <!-- 회원정보 //-->
                          <td :rowspan="record.children.length + 1" class="text-center">
                            <template v-if="record.user">
                              <partner-badge :partner="record.user"></partner-badge>
                              <span class="ms-1"><router-link :to="'/user/detail/' + record.user.id" target="_blank" v-if="record.user.type === 'user'">{{ record.user.username }}</router-link></span>
                              <span class="ms-1"><router-link :to="'/partner/detail/' + record.user.id" target="_blank" v-if="record.user.type === 'partner'">{{ record.user.username }}</router-link></span>
                              <!--
                              <br />
                              <span v-if="record.groupKey">{{ record.groupKey }} - </span> {{ record.siteUsername }}
                              //-->
                            </template>
                          </td>
                          <!-- 거래 형식 //-->
                          <td :rowspan="record.children.length + 1">
                            <badge-seamless :use-seamless="record.parent.useSeamless" v-if="record.parent && record.category === 'turn'"></badge-seamless>
                            <badge-transaction-category :record="record"></badge-transaction-category>
                          </td>
                          <!-- 게임사 //-->
                          <td :rowspan="record.children.length + 1">{{ record.vendorName }}</td>
                          <!-- 게임종류 //-->
                          <td :rowspan="record.children.length + 1">
                            <badge-game-category :record="record"></badge-game-category>
                          </td>
                          <!-- 게임형식 //-->
                          <td :rowspan="record.children.length + 1">{{ record.gameTypeName.toUpperCase() }}</td>
                          <!-- 게임명 //-->
                          <td :class="{'text-start': record.gameCategoryKey === 'slot'}" :rowspan="record.children.length + 1">
                            {{ record.categoryKey }}
                            <img :src="record.gameImage" style="width: 32px;" class="img-thumbnail" v-if="record.gameImage" />
                            {{ record.gameName }}
                          </td>
                          <!-- 거래 방식 //-->
                          <td>
                            <!--
                            method: game, category: money, type: send or receive => 사이트<->케이곤 머니이동
                            method: cash, category: cash(파트너) or money(회원), type: send or receive => 회원간 캐쉬 이동(입/출금)
                            method: game, category: turn, type: bet or win or draw or cancel => 게임 플레이
                            //-->
                            <span class="badge ms-1 badge-soft-danger" style="cursor: pointer;" v-if="record.parent.useSeamless === 1 && record.seamlessState === 'error'" v-b-tooltip.hover data-placement="top" :title="record.seamlessErrorMsg" @click="openSeamlessLog(record)"><i class="fa fa-check"></i></span>
                            <span class="badge badge-soft-dark" style="cursor: pointer;" @click="openData(record)" v-b-tooltip.hover data-placement="top" :title="'게임데이터'"><i class="fa fa-book"></i></span>
                            <badge-transaction-type :record="record"></badge-transaction-type>
                          </td>
                          <!-- 이전 보유알 //-->
                          <td class="text-end">{{ record.beforeAmount.floatVal(2).formatThousands() }}</td>
                          <!-- 금액 //-->
                          <td class="text-end">
                            <!-- 에볼류션 //-->
                            <template v-if="record.vendorKey === 'vegas_casino'">
                              <p v-for="(bet, index) in record.data.bets" :key="record.transactionKey + '_' + index">
                                <badge-game-target :target="bet.betType"></badge-game-target>&nbsp;
                                <span>{{ bet.betAmount.formatThousands() }}</span>
                              </p>
                            </template>
                            <template v-else>
                              {{ record.amount.formatThousands() }}
                            </template>
                          </td>
                          <!-- 수익금 //-->
                          <td class="text-end">
                            <template v-if="record.vendorKey === 'vegas_casino'">
                              <p v-for="(bet, index) in record.data.payoffs" :key="record.transactionKey + '_result_' + index">
                                <badge-game-target :target="record.type.toLowerCase()" v-if="record.data && record.data.payoffs"></badge-game-target>&nbsp;
                                <span :class="{'text-danger': bet.totalPayoffAmount <= 0, 'text-primary': bet.totalPayoffAmount > 0}">{{ bet.totalPayoffAmount.formatThousands() }}</span>
                              </p>
                            </template>
                          </td>
                          <!-- 이후 보유알 //-->
                          <td class="text-end">{{ record.afterAmount.floatVal(2).formatThousands() }}</td>
                          <!-- 요청일(requestedAt) //-->
                            <!--
                          <td class="small">{{ new Date(record.requestedAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
                          -->
                          <!-- 처리일(createdAt) //-->
                          <td class="small">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
                        </tr>

                        <tr v-for="(child, index) in record.children" :key="'result-' + record.transactionKey + '-' + index" class="border-bottom border-5">
                          <td>
                            <span class="badge ms-1" style="cursor: pointer;" v-if="child.parent.useSeamless === 1 && child.seamlessState === 'error'" :class="{'badge-soft-danger': child.seamlessState === 'error', 'badge-soft-primary': child.seamlessState === 'finish', 'badge-soft-secondary': child.seamlessState === 'ready'}" v-b-tooltip.hover data-placement="top" :title="child.seamlessErrorMsg" @click="openSeamlessLog(child)"><i class="fa fa-check"></i></span>
                            <span class="badge badge-soft-dark" style="cursor: pointer;" @click="openData(child)" v-b-tooltip.hover data-placement="top" :title="'결과데이터'"><i class="fa fa-book"></i></span>
                            <badge-transaction-type :record="child"></badge-transaction-type>
                          </td>
                          <td class="text-end">{{ child.beforeAmount.floatVal(2).formatThousands() }}</td>
                          <td>
                            <span v-if="record.vendorKey === 'evolution_casino'">{{ record.amount.formatThousands() }}</span>
                          </td>
                          <td class="text-end">
                            <span :class="{'text-danger': child.amount <= 0, 'text-primary': child.amount > 0}">
                              {{ child.amount.formatThousands() }}
                            </span>
                          </td>
                          <td class="text-end">{{ child.afterAmount.floatVal(2).formatThousands() }}</td>
                          <!--<td class="small">{{ new Date(child.requestedAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>-->
                          <td class="small">{{ new Date(child.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
                        </tr>
                      </template>
                    </tbody>
                  </table>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Detail ref="modal-detail" />
  </div>
</template>
