export default class VivoParser {
    constructor() {
        this.cards = {
            "14$1" : ['sa.png', 1, 1],
            "2$1" : ['s2.png', 2, 2],
            "3$1" : ['s3.png', 3, 3],
            "4$1" : ['s4.png', 4, 4],
            "5$1" : ['s5.png', 5, 5],
            "6$1" : ['s6.png', 6, 6],
            "7$1" : ['s7.png', 7, 7],
            "8$1" : ['s8.png', 8, 8],
            "9$1" : ['s9.png', 9, 9],
            "10$1" :['st.png', 10, 10],
            "11$1" :['sj.png', 10, 11],
            "12$1" : ['sq.png', 10, 12],
            "13$1" : ['sk.png', 10, 13],

            "14$3" : ['da.png', 1, 1],
            "2$3" : ['d2.png', 2, 2],
            "3$3" : ['d3.png', 3, 3],
            "4$3" : ['d4.png', 4, 4],
            "5$3" : ['d5.png', 5, 5],
            "6$3" : ['d6.png', 6, 6],
            "7$3" : ['d7.png', 7, 7],
            "8$3" : ['d8.png', 8, 8],
            "9$3" : ['d9.png', 9, 9],
            "10$3" : ['dt.png', 10, 10],
            "11$3" : ['dj.png', 10, 11],
            "12$3" : ['dq.png', 10, 12],
            "13$3" : ['dk.png', 10, 13],

            "14$4" : ['ca.png', 1, 1],
            "2$4" : ['c2.png', 2, 2],
            "3$4" : ['c3.png', 3, 3],
            "4$4" : ['c4.png', 4, 4],
            "5$4" : ['c5.png', 5, 5],
            "6$4" : ['c6.png', 6, 6],
            "7$4" : ['c7.png', 7, 7],
            "8$4" : ['c8.png', 8, 8],
            "9$4" : ['c9.png', 9, 9],
            "10$4" : ['ct.png', 10, 10],
            "11$4" : ['cj.png', 10, 11],
            "12$4" : ['cq.png', 10, 12],
            "13$4" : ['ck.png', 10, 13],

            "14$2" : ['ha.png', 1, 1],
            "2$2" : ['h2.png', 2, 2],
            "3$2" : ['h3.png', 3, 3],
            "4$2" : ['h4.png', 4, 4],
            "5$2" : ['h5.png', 5, 5],
            "6$2" : ['h6.png', 6, 6],
            "7$2" : ['h7.png', 7, 7],
            "8$2" : ['h8.png', 8, 8],
            "9$2" : ['h9.png', 9, 9],
            "10$2" : ['ht.png', 10, 10],
            "11$2" : ['hj.png', 10, 11],
            "12$2" : ['hq.png', 10, 12],
            "13$2" : ['hk.png', 10, 13]
        }

        this.target = {
            "3" : '타이',
            "1" : '플레이어',
            "2" : '뱅커',
            "4" : '플레이어페어',
            "5" : '뱅커페어',
            "6" : '플레이어보너스',
            "7" : '뱅커보너스',
            "8" : '퍼펙트페어',
            "9" : '이더페어',
            "小" : '소',
            "大" : '대',

            "下注龙赢" : '드래곤',
            "下注龙单" : '드래곤홀',
            "下注龙双" : '드래곤짝',
            "下注龙黑" : '드래곤블랙',
            "下注龙红" : '드래곤레드',
            "下注虎赢" : '타이거',
            "下注虎单" : '타이거홀',
            "下注虎双" : '타이거짝',
            "下注虎黑" : '타이거블랙',
            "下注虎红" : '타이거레드'
        }
    }


    set (data) {
        if (! data) return {code: -1, msg: 'detail 데이터가 존재하지 않습니다.'}

        return this.setGame(data)
    }

    setGame (betData) {
        // table
        if (! betData.data) {
            return {code: -1, msg: 'data가 존재하지 않습니다.'}
        }
/*
        // 카드정보
        if (! detailData.gameResult) {
            return {code: -1, msg: '카드정보가 존재하지 않습니다.'}
        }
*/
        if (! betData.data.History) {
            return {code: -1, msg: '배팅정보가 존재하지 않습니다.'}
        }

        if(betData.gameTypeName.toLowerCase() != "baccarat"){
            return false
        }


        //결과가 들어왔다면 세팅
        let resultData
        let arrWins = []
        let arrCards = []
        if (betData.children.length > 0) {
            resultData = betData.children[0].data

            // resultData.data = BACCARAT:WINS;4-54000,9-36000:CARDS=P:7$2,B:13$2,P:7$2,B:2$2,P:8$3,B:4$2>BANKER
            let tmp = resultData.History.split(":CARDS=")
            // tmp[0] = BACCARAT:WINS;4-54000,9-36000
            // tmp[1] = P:7$2,B:13$2,P:7$2,B:2$2,P:8$3,B:4$2>BANKER
            let tmp2 = tmp[0].split(";")
            // tmp2[0] = BACCARAT:WINS
            // tmp2[1] = 4-54000,9-36000
            if(tmp2.length >= 2 && tmp2[1]) arrWins = tmp2[1].split(",")
            let tmp3 = tmp[1].split(">")
            // tmp3[0] = P:7$2,B:13$2,P:7$2,B:2$2,P:8$3,B:4$2
            // tmp3[1] = BANKER
            arrCards = tmp3[0].split(",")



        }


        const userBets = []


        let arrBets = betData.data.History.split(",")
        for(let key in arrBets) {

            let tmp = arrBets[key].split("-")
            let betId = tmp[0]
            let betAmount = tmp[1]
            let betTarget = this.target[betId]

            let winAmount = 0
            for(let key2 in arrWins) {
                let tmp2 = arrWins[key2].split("-")
                let winId = tmp2[0]
                if(betId == winId) winAmount = tmp2[1]
            }


            userBets.push({
                target: betTarget,
                bet: betAmount,
                win: winAmount,
                placedOn: betData.createdAt
            })



        }


        /*
        for(let key in detailParticipantsData) {
            const part = detailParticipantsData[key]
            const bets = part.bets
            for(let key2 in bets) {
                const bet = bets[key2]

                let betTarget = bet.description

                let betAmount = parseFloat(bet.stake)
                let winAmount = parseFloat(bet.payout)

                userBets.push({
                    target: betTarget,
                    bet: betAmount,
                    win: winAmount,
                    placedOn: bet.placedOn,
                    id: bet.transactionId
                })
            }
        }

         */







        let result = {} //BANKER, PLAYER, DRAGON, TIGER


        let detail = null

        if (betData.gameTypeName.toLowerCase() == "baccarat") {
            // 바카라, 드래곤타이거, 룰렛, 블랙잭, 식보는 이미지 적용


            result.banker = {}
            result.player = {}
            result.banker.cards = []
            result.player.cards = []

            //결과를 파싱한다.

            for (let i = 0; i < arrCards.length; i++) {
                //P:7$2
                let tmp = arrCards[i].split(":")

                if(tmp[0].toUpperCase() == "P"){
                    result.player.cards.push(tmp[1])

                }
                else if(tmp[0].toUpperCase() == "B"){
                    result.banker.cards.push(tmp[1])
                }
            }


            // 뱅커
            let bankerScore = 0
            let bankerCards = []

            // 플레이어
            let playerScore = 0
            let playerCards = []

            for(let key in result.banker.cards) {
                let c = result.banker.cards[key]

                let card = this.cards[c]
                if (key == 2) {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/L_' + card[0])
                } else {
                    bankerCards.push('https://static.uniongame.org/public/images/cards/' + card[0])
                }
                bankerScore += card[1]
            }

            for(let key in result.player.cards) {
                let c = result.player.cards[key]
                let card = this.cards[c]
                if (key == 2) {
                    playerCards.push('https://static.uniongame.org/public/images/cards/L_' + card[0])
                } else {
                    playerCards.push('https://static.uniongame.org/public/images/cards/' + card[0])
                }
                playerScore += card[1]
            }


            bankerScore = bankerScore+""
            bankerScore = bankerScore.charAt(bankerScore.length-1)
            bankerScore = bankerScore * 1

            playerScore = playerScore+""
            playerScore = playerScore.charAt(playerScore.length-1)
            playerScore = playerScore * 1


            if(bankerScore > playerScore) result.outcome = "BANKER"
            else if(playerScore > bankerScore) result.outcome = "PLAYER"
            else if(bankerScore == playerScore) result.outcome = "TIE"
            else result.outcome = "UNKNOWN"

            detail = {
                banker: {
                    cards: bankerCards,
                    score: bankerScore
                },
                player: {
                    cards: playerCards,
                    score: playerScore
                },
            }
        }

        try {
            result.outcome ? result.outcome.toLowerCase() : null
        } catch (e) {
            result.outcome = null
        }

        return {
            code: 0,
            bets: userBets,
            detail: detail,
            result: result.outcome ? result.outcome.toLowerCase() : null
        }
    }
}
